import { createActionGroup, emptyProps } from '@ngrx/store';

export const USER_ACTIONS = createActionGroup({
  source: 'User',
  events: {
    'Sign Out': emptyProps(),
    'Sign Up Without Lib Claim': emptyProps(),
    'Sign Up Succeeded': emptyProps(),
    'Sign Out From Reauth': emptyProps(),
    'Sign Out Initiate': emptyProps(),
  },
});
