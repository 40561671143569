import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/services';
import { MeasurementType } from '@digi.me/models';
import { Observation } from '@hl7fhir';
import { Logger } from '@logging';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DEMO_MODE_ACTIONS, TOAST_ACTIONS } from '@store/app';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map } from 'rxjs/operators';
import { OBSERVATION_UI_ACTIONS } from '../../hl7fhir/observation';
import { STORAGE_FILE_API_ACTIONS } from '../digi-me.actions';

@Injectable()
export class DigiMeStorageEffects {
  addStorageFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.addStorageFile),
        exhaustMap(({ file, fileName, filePath, measurementType }) => {
          return this.apiService.addStorageFile(file, fileName, filePath).pipe(
            map((response: HttpResponse<any>) => {
              const navigationPath = (() => {
                switch (measurementType) {
                  case MeasurementType.height:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'body-heights'];
                  case MeasurementType.weight:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'body-weights'];
                  case MeasurementType.bloodGlucose:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'glucoses'];
                  case MeasurementType.bloodPressure:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'blood-pressure'];
                  case MeasurementType.bodyTemperature:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'body-temperatures'];
                  case MeasurementType.heartRate:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'heart-and-pulse-rates'];
                  case MeasurementType.o2Saturation:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'o2-saturations'];
                  case MeasurementType.respirationRate:
                    return [`${$localize.locale}`, 'my-health-record', 'measurements', 'respiratory'];
                  default:
                    return null;
                }
              })();

              if (navigationPath) {
                this.router.navigate(navigationPath);
              }

              return STORAGE_FILE_API_ACTIONS.addStorageFileSucceeded({
                file: response.body.file,
                fileName,
                measurementType,
              });
            }),
            catchError((error) => {
              error.headers.get('');
              return of(
                STORAGE_FILE_API_ACTIONS.addStorageFileFailed({
                  error,
                  fileName,
                  file,
                  measurementType,
                }),
              );
            }),
          );
        }),
      );
    },
    { dispatch: true },
  );

  addAppleHealthStorageFile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFile),
        concatMap(({ file, fileName, filePath, measurementType }) => {
          return this.apiService.addStorageFile(file, fileName, filePath).pipe(
            map((response: HttpResponse<any>) =>
              STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileSucceeded({
                file: response.body.file,
                fileName,
                measurementType,
              }),
            ),
            catchError((error) => {
              // Force initialize headers, otherwise they don't serialize
              error.headers.get('');
              return of(
                STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileFailed({ file, error, fileName, measurementType }),
              );
            }),
          );
        }),
      );
    },
    { dispatch: true },
  );

  appleHealthStorageFilesSucceeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesSucceeded),
      map(() =>
        TOAST_ACTIONS.showToast({
          message: $localize`:@@app.toast.uploadSuccess:Your Apple Health data has been imported.`,
          typeMessage: 'info',
        }),
      ),
    );
  });

  appleHealthStorageFilesReset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesSucceeded,
        STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesFailed,
      ),
      map(() => STORAGE_FILE_API_ACTIONS.resetAppleHealthStorageFiles()),
    );
  });

  appleHealthStorageFilesFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(STORAGE_FILE_API_ACTIONS.appleHealthStorageFilesFailed),
      map(() =>
        TOAST_ACTIONS.showToast({
          message: $localize`:@@app.toast.uploadFailed:Some files failed to upload.`,
          typeMessage: 'error',
        }),
      ),
    );
  });

  addSelfMeasurementToList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          STORAGE_FILE_API_ACTIONS.addStorageFileSucceeded,
          STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileSucceeded,
        ),
        map((response) => response.file),
        filter((file: Observation) => file.resourceType === 'Observation'),
        map((observation: Observation) =>
          OBSERVATION_UI_ACTIONS.addToList({
            observation,
          }),
        ),
      );
    },
    { dispatch: true },
  );

  sendException$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.addStorageFileFailed, STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileFailed),
        filter(({ file }) => file.resourceType !== 'Observation'), // Observations are tracked in their own event-log effect file
        exhaustMap((error) => this.logger.logApiException(error.error)),
      );
    },
    { dispatch: false },
  );

  openDemoModeModal$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.addStorageFileFailed, STORAGE_FILE_API_ACTIONS.addAppleHealthStorageFileFailed),
        filter((action) => action.error.status === -1),
        exhaustMap(() => {
          return of(DEMO_MODE_ACTIONS.showDemoModeModal());
        }),
      );
    },
    { dispatch: true },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly logger: Logger,
  ) {}
}
