import { HttpErrorResponse } from '@angular/common/http';
import { AccountsResponse } from '@digi.me/models';
import { Observation } from '@hl7fhir';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OBSERVATION_UI_ACTIONS = createActionGroup({
  source: 'Observations UI',
  events: {
    'Add To List': props<{ observation: Observation }>(),
    Remove: props<{ observation: Observation; profile: string; navigateTo: string }>(),
    'Remove Success': props<{ observation: Observation; profile: string }>(),
    'Remove Failed': props<{ error: HttpErrorResponse; observation: Observation; profile: string }>(),
  },
});

export const DOWNLOAD_OBSERVATION_ACTIONS = createActionGroup({
  source: 'Download Observation UI',
  events: {
    Requested: props<{ observation: Observation; profile: string }>(),
    Succeeded: props<{ observation: Observation; profile: string }>(),
    Failed: props<{ observation: Observation; profile: string; error: any }>(),
  },
});

export const SHARE_OBSERVATION_ACTIONS = createActionGroup({
  source: 'Share Observation UI',
  events: {
    'Find Account Succeeded': props<{ account: AccountsResponse }>(),
    Reset: emptyProps(),
  },
});
