import { Injectable } from '@angular/core';
import { DigiMeEvents, ErrorOrigin, MixpanelEventProperties } from '@digi.me/models';
import { EMPTY, Observable } from 'rxjs';
// We use the ApiService from the core module, and the services in the core module utilize the Mixpanel service,
// which is why a circular dependency issue arises.
// ! TODO We need to address and resolve this issue.
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiService } from '../core/services/api.service';
import { Logger } from './logger.class';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService extends Logger {
  constructor(private readonly apiService: ApiService) {
    super();
  }

  logEvent(
    eventName: DigiMeEvents,
    properties?: Record<string, unknown>,
    error?: Error,
  ): Observable<HttpResponse<null>> {
    return this.apiService.eventLog(eventName, {
      ...properties,
      ...new MixpanelEventProperties(),
      ...this.getErrorProperties(error),
    });
  }

  logApiException(error: HttpErrorResponse): Observable<HttpResponse<null>> {
    switch (error.status) {
      case -1:
      case 0:
      case 401:
      case 403:
      case 429:
        return EMPTY;
      default:
        return this.logEvent('error', this.getApiProperties(error));
    }
  }

  logException(error: Error): Observable<HttpResponse<null>> {
    return this.logEvent('error', this.getErrorProperties(error));
  }

  private getErrorProperties(error: Error | undefined): Record<string, unknown> {
    if (!error) {
      return {};
    }
    return {
      error_origin: ErrorOrigin.app,
      error_stack_trace: error.stack,
      error_name: error.name,
      error_message: error.message,
    };
  }

  private getApiProperties(httpErrorResponse: HttpErrorResponse | undefined): Record<string, unknown> {
    if (!httpErrorResponse) {
      return {};
    }
    const correlationId = httpErrorResponse.headers.get('x-ms-invocation-id');
    const shared = {
      error_api_correlation_id: correlationId,
      error_api_url: httpErrorResponse.url,
      error_api_error: httpErrorResponse.error,
    };
    const errorOrigin = httpErrorResponse?.error?.error?.type === 'sdk' ? ErrorOrigin.sdk : ErrorOrigin.api;
    if (errorOrigin == ErrorOrigin.sdk) {
      return {
        error_origin: ErrorOrigin.sdk,
        error_api_message: httpErrorResponse.error.error.message,
        error_api_code: httpErrorResponse.error.error.code,
        ...shared,
      };
    } else {
      return {
        error_origin: errorOrigin,
        error_api_message: httpErrorResponse.message,
        error_api_code: httpErrorResponse.status.toString(),
        ...shared,
      };
    }
  }
}
