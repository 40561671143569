import { ParamMap } from '@angular/router';

interface AzureAdB2CCallbackError {
  error?: string;
  code?: string;
  description?: string;
  correlationId?: string;
  timestamp?: string;
}

/**
 * Parses the returned error query params of Azure AD B2C.
 *
 * E.g. the url:
 * ?error=access_denied&error_description=AADB2C90091:%20The%20user%20has%20cancelled%20entering%20self-asserted%20information.%0D%0ACorrelation%20ID:%204fed83a2-c125-4d66-ac97-38f8142a1d2d%0D%0ATimestamp:%202024-12-23%2009:46:22Z%0D%0A&state=639ea1960ee387325cc188578ba086e1a8zOk8Tu3
 *
 * Into:
 * {
 *  error: 'access_denied',
 *  code: 'AADB2C90091',
 *  description: 'The user has cancelled entering self-asserted information.',
 *  correlationId: '4fed83a2-c125-4d66-ac97-38f8142a1d2d',
 *  timestamp: '202024-12-23%2009:46:22Z'
 * }
 * @param queryParamMap the query params from the url
 * @returns the parsed Azure AD B2C error object
 */
export function extractErrorFromQueryParams(queryParamMap: ParamMap): AzureAdB2CCallbackError {
  const error = queryParamMap.get('error') ?? undefined;
  const encodedDescription = queryParamMap.get('error_description');

  const descriptionDecoded = encodedDescription ? decodeURI(encodedDescription) : undefined;
  const parts = descriptionDecoded?.split(/\r?\n/);
  const descriptionPart = parts?.find((part) => part.startsWith('AADB2C'));
  const correlationIdPart = parts?.find((part) => part.startsWith('Correlation ID:'));
  const timestampPart = parts?.find((part) => part.startsWith('Timestamp: '));

  const descriptionPartSplitted = descriptionPart?.split(': ');
  const code = descriptionPartSplitted?.[0];
  const description = descriptionPartSplitted?.[1] ?? descriptionDecoded;
  const correlationId = correlationIdPart?.substring('Correlation ID: '.length);
  const timestamp = timestampPart?.substring('Timestamp: '.length);

  return {
    error,
    code,
    description,
    correlationId,
    timestamp,
  };
}
