import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Logger } from '@logging';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly logger: Logger) {}

  handleError(error: any) {
    if (environment.mixPanel.enabled) {
      this.logger.logException(error).subscribe();
    }

    if (!environment.production) {
      console.log('GlobalErrorHandler', error);
    }
  }
}
