import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AuthenticationService } from '@core/services';
import { configId } from '@environments/environment';
import { getUrlHandler, PreferencesKeys } from '@globals';
import { toAzureAdB2c } from '@language';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoginResponse, LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { delay, exhaustMap, filter, forkJoin, map, of, switchMap, tap } from 'rxjs';
// The same issue with imports as in mixpanel.service.ts
// ! TODO We need to address and resolve this issue.
import { Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { selectIsDemoUser } from '@store/digi.me';
import { DeviceService } from '../../core/services/device.service';
import { APP_ACTIONS, DEMO_MODE_ACTIONS } from '../app/actions/app.actions';
import { USER_ACTIONS } from './user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  /**
   * Effect that handles the sign out action.
   * Removes the device ID, logs off and revokes tokens for the current user.
   */
  signOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOut),
        // Set a timeout to dispatch the 'sign_out' event using Mixpanel before initiating the user's logoff process.
        // This ensures that the 'sign_out' event is dispatched prior to the user being logged off. If the user is logged
        // off before the 'sign_out' event is dispatched, the event will not be sent. This occurs because the user is logged
        // off before the HTTP request to Mixpanel is completed, resulting in the request being canceled.
        delay(500),
        switchMap(() => {
          const logoutOptions: LogoutAuthOptions = {
            urlHandler: () => {
              return '';
            },
          };

          return forkJoin([
            this.oidcSecurityService.logoffAndRevokeTokens(configId, logoutOptions),
            this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`, logoutOptions),
            this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-reset`, logoutOptions),
          ]);
        }),
        tap(async () => {
          this.oidcSecurityService.logoffLocalMultiple();
          this.deviceService.removeDeviceId();
          this.deviceService.removeFromLocalStorage();

          await this.router.navigate([`${$localize.locale}`, 'onboard-or-log-in']);
        }),
        map(() => APP_ACTIONS.resetGlobalState()),
      );
    },
    {
      dispatch: true,
    },
  );

  signOutDemoCheck$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOutInitiate),
        concatLatestFrom(() => this.store.select(selectIsDemoUser)),
        exhaustMap(([, isDemoUser]) => {
          if (isDemoUser) {
            return of(DEMO_MODE_ACTIONS.showDemoModeModal());
          } else {
            return of(USER_ACTIONS.signOut());
          }
        }),
      );
    },
    { dispatch: true },
  );

  resetBiometricsConfig$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.resetGlobalState, APP_ACTIONS.timeoutLogout),
        filter(() => Capacitor.isNativePlatform()),
        tap(async () => {
          await Preferences.remove({ key: configId });

          // This configs are  also stored by oidc-lib.
          await Preferences.remove({ key: `${configId}-reset` });
          await Preferences.remove({ key: `${configId}-signup` });

          await Preferences.remove({ key: PreferencesKeys.ENABLE_BIOMETRICS });
          await Preferences.remove({ key: PreferencesKeys.HIDE_USE_BIOMETRICS_MODAL });
        }),
      );
    },
    { dispatch: false },
  );

  signUp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signUpWithoutLibClaim),
        tap(() => {
          const customParams: Record<string, string> = {
            ui_locales: toAzureAdB2c($localize.locale),
            // If the application is running on a native platform which uses an embedded browser, prompts the user
            // for reauthentication to ensure that older session data is not used.
            ...(Capacitor.isNativePlatform() && { prompt: 'login' }),
          };

          this.oidcSecurityService.authorize(`${configId}-signup`, {
            customParams,
            urlHandler: getUrlHandler(),
          });
        }),
      );
    },
    { dispatch: false },
  );

  authenticate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signUpSucceeded),
        tap(() => {
          this.oidcSecurityService.logoffLocal(`${configId}-signup`);
        }),
        exhaustMap(() => this.oidcSecurityService.setState('cleaned', `${configId}-signup`)),
        map(() => APP_ACTIONS.authenticationStarted({ skipLog: true })),
      );
    },
    { dispatch: true },
  );

  trySessionRefreshIfBiometricsAreEnabled$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.unauthenticated),
        // Check if the platform is iOS or Android
        filter(() => Capacitor.isNativePlatform()),
        // Check if the user has enabled biometrics
        // Then we can assume that the refresh token exists in the storage
        switchMap(() => this.authenticationService.useBiometrics$),
        filter((useBiometrics: boolean) => useBiometrics),
        // Try to refresh the session
        exhaustMap(() => this.oidcSecurityService.forceRefreshSession(undefined, configId)),
        // Check again if the user is authenticated with the new credentials
        switchMap(() => this.oidcSecurityService.checkAuth(`${window.location.origin}`, configId)),
        filter((loginResponse: LoginResponse) => loginResponse.isAuthenticated),
        map(() => APP_ACTIONS.authenticated({})),
      );
    },
    { dispatch: true },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly deviceService: DeviceService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}
}
