import { createSelector } from '@ngrx/store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectUserResponse = createSelector(selectDigiMeState, (state: DigiMeState) => state.user.data);

export const selectUser = createSelector(selectDigiMeState, (state: DigiMeState) => state.user.data?.user ?? null);

export const selectUserReauth = createSelector(selectDigiMeState, (state: DigiMeState) => {
  return !state.user.data ? undefined : (state.user.data?.userReauth ?? null);
});

export const selectUserFitnessIds = createSelector(selectDigiMeState, (state: DigiMeState) => {
  return state.user.data?.fitnessApps ?? {};
});
