import { Preferences } from '@capacitor/preferences';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
export class MobileOidcStorageService implements AbstractSecurityStorage {
  private readonly queue = new PromiseQueue();

  read(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  write(key: string, value: string) {
    this.queue.enqueue(async () => {
      await Preferences.set({
        key,
        value,
      });
    });
    sessionStorage.setItem(key, value);
  }

  remove(key: string) {
    this.queue.enqueue(async () => {
      await Preferences.remove({ key });
    });

    sessionStorage.removeItem(key);
  }

  clear() {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.startsWith('azureb2c')) {
        this.remove(key);
      }
    }
  }
}

class PromiseQueue {
  private queue: (() => Promise<void>)[] = [];
  private isProcessing = false;

  // Add a promise to the queue
  enqueue(promiseFunction: () => Promise<void>) {
    this.queue.push(promiseFunction);
    this.processQueue(); // Start processing if not already running
  }

  // Process the queue sequentially
  private async processQueue() {
    if (this.isProcessing) return; // Avoid concurrent processing

    this.isProcessing = true;

    while (this.queue.length > 0) {
      const promiseFunction = this.queue.shift();
      if (promiseFunction) {
        try {
          await promiseFunction(); // Wait for the current promise to resolve
        } catch (error) {
          console.error('Error in queued promise:', error);
        }
      }
    }

    this.isProcessing = false; // Mark processing as complete
  }
}
