import { AccountSyncStatusEntryPartial, CAFileListResponse, Status } from '@digi.me/models';
import { createSelector } from '@ngrx/store';
import { DigiMeState } from '../digi-me.reducer';
import { selectFilesLoaded } from './digi-me-files.selectors';
import { selectDigiMeState } from './digi-me.selectors';

export const selectFileList = createSelector(selectDigiMeState, (state: DigiMeState) => state?.fileList.data);

export const selectFileListStatus = createSelector(selectFileList, (state: CAFileListResponse | null) => state?.status);

export const selectShouldReauthorize = createSelector(selectFileListStatus, (state: Status | undefined) => {
  const details = state?.details;
  const accountId = extractAccountId(details);

  if (accountId && details?.[accountId] && 'error' in details[accountId]) {
    const syncStatus = details[accountId] as AccountSyncStatusEntryPartial;
    return syncStatus.error?.reauth ?? false;
  }

  return false;
});

export const selectBinaryFiles = createSelector(selectFileList, (fileList) =>
  fileList?.fileList?.filter((file) => file.objectType === 'binary'),
);

export const selectAllBinariesLoaded = createSelector(
  selectBinaryFiles,
  selectFilesLoaded,
  (binaryFiles, filesLoaded) => binaryFiles?.every((file) => filesLoaded.includes(file.name)) ?? true,
);

// TODO Create utils class and move the function
function extractAccountId(error: any): string | undefined {
  if (!error) {
    return undefined;
  }
  const keys = Object.keys(error);
  if (keys.length === 0) {
    return undefined;
  }
  return keys[0] as string;
}
