import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsDemoUser } from '@store/digi.me';
import { Observable, switchMap, take, throwError } from 'rxjs';

@Injectable()
export class DemoModeInterceptor implements HttpInterceptor {
  private readonly demoUrls = [
    'reset',
    'update-ad-user',
    'authorize-url',
    'upload-storage-file',
    'reauthorize',
    'revoke',
    'delete-account',
  ];

  constructor(private readonly store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectIsDemoUser).pipe(
      take(1),
      switchMap((isDemoUser) => {
        if (isDemoUser && this.isDemoUrl(req.url)) {
          const demoError = new HttpErrorResponse({
            error: { message: 'Demo mode: Request blocked.' },
            status: -1,
          });

          return throwError(() => demoError);
        }
        return next.handle(req);
      }),
    );
  }

  private isDemoUrl(url: string): boolean {
    return this.demoUrls.some((demoUrl) => url.includes(demoUrl));
  }
}
