import { Pipe, PipeTransform } from '@angular/core';
import { HumanName } from '@hl7fhir';
import { HumanNameComparer } from '@hl7fhir/comparers';

@Pipe({
  name: 'humanNameRank',
})
export class HumanNameRankPipe implements PipeTransform {
  transform(value: HumanName[] | undefined | null): HumanName | null {
    if (!value) return null;

    if (value.length == 1) {
      return value[0];
    }

    const sortedValue: HumanName[] = [...value].sort((a: HumanName, b: HumanName) => HumanNameComparer.compare(a, b));

    // Sort and return first
    return sortedValue[0];
  }
}
