import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DigiMeEvents } from '@digi.me/models';
import { Observable } from 'rxjs';

export abstract class Logger {
  abstract logEvent(
    eventName: DigiMeEvents,
    properties?: Record<string, unknown>,
    error?: Error,
  ): Observable<HttpResponse<null>>;

  abstract logApiException(error: HttpErrorResponse): Observable<HttpResponse<null>>;
  abstract logException(error: Error): Observable<HttpResponse<null>>;
}
